import React from 'react'
import { WhiteSpace,Toast,Button } from 'antd-mobile';
import {axiosGet} from './server/serverapp';
//const Item = List.Item;
//const Brief = Item.Brief;
class StudentList extends React.Component {
    constructor(props){
        super(props)
        this.state={
            phone:'',
            liked:true,
            timeer:60
        }
        this.phoneRef = React.createRef()
        this.codeRef = React.createRef()

    }
    
    toPromise = async () => {
        let phoneNum = this.phoneRef.current.value
        let codeNum = this.codeRef.current.value
        const islogin = await axiosGet('histdata',{phone:phoneNum, phonecode:codeNum});
        console.log(islogin)
        if(islogin.retCode == 0){
            this.props.history.push('/student',{
                dotData: islogin.retData.sutdent
            })
        }else{
            Toast.info(islogin.retMsg, 3);
        }
        
    }
    componentDidMount(){
        document.title = '短信验证'
    }
    getYanzheng = async() => {
        let likedType = this.state.liked
        let phoneNum = this.phoneRef.current.value
        
        
        if(likedType){
            let sentcode = await axiosGet('getCode',{phone:phoneNum})
            Toast.info(sentcode.retMsg, 3);
            if(sentcode.retCode == '0'){
                this.setState({liked:false})
                this.timerID = setInterval(()=>{
                    let t = this.state.timeer
                        this.setState({
                            timeer: t - 1
                        });
                        if(0 >= t){
                            this.setState({liked:true,timeer:60})
                            clearInterval(this.timerID);
                        }
                },1000)
            }
        }
        
    }
    componentWillUnmount() {
        clearInterval(this.timerID);
      }
    render() {
        let inputimg = [process.env.PUBLIC_URL + '/static/img/error.png',process.env.PUBLIC_URL + '/static/img/perphone.png']
        let getState = this.state.liked ? 'cangetyan':'cantgetyan'
        return (
            <div>
                {/* <NavBar
                    mode="light"
                    icon={<Icon type="left" />}
                    onLeftClick={this.toBack}
                >
                    短信验证
                </NavBar> */}
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <img style={{width:"110px",height:"125px",display:"block",margin:"0 auto"}} src={inputimg[0]} />
                <p style={{fontSize:"20px",fontWeight:"bold",textAlign:"center",marginTop:"26px"}}>
                手机号和孩子姓名不匹配
                    </p>
                    <p style={{fontSize:"16px",textAlign:"center",marginTop:"20px"}}>为确保绑定正确的孩子，需进行短信验证</p>
                    <div style={{width:"94%",position:"relative",margin:'28px auto'}}>
                        <input type='text' defaultValue={this.props.location.state.phoneNum}  ref={this.phoneRef} maxLength="11" style={{backgroundColor:'#F9F9F9',width:"100%",height:"48px",outline:"none",borderRadius:"24px",border:'none',padding:'0 50px'}} placeholder="请输入孩子登录英语说的手机号" />
                        <img style={{ width: '20px',height:"20px",position:'absolute',left:'20px',top:"14px" }} src={inputimg[1]} />
                    </div>
                    <div style={{width:"90%",margin:'28px auto',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                        <div style={{width:"56%",position:"relative"}}>
                            <input type='text' ref={this.codeRef} style={{backgroundColor:'#F9F9F9',width:"90%",height:"48px",outline:"none",borderRadius:"24px",border:'none',padding:'0 0 0 50px'}} placeholder="请输入验证码" />
                            <img style={{ width: '20px',height:"20px",position:'absolute',left:'20px',top:"14px" }} src={inputimg[1]} />
                        </div>
                        <div onClick={this.getYanzheng} className={getState}  style={{width:"40%",lineHeight:"48px",color:"#575D62", borderRadius:"24px",border:'none',padding:'0 15px',textAlign:"center"}}>
                            {this.state.liked ? "获取验证码" : this.state.timeer}
                        </div>
                    </div>
                    <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace /><WhiteSpace />
                <WhiteSpace />
                    <Button type="primary" onClick={this.toPromise} style={{ width:"90%",height:'48px',borderRadius:'24px',backgroundColor: "#30CC75", outline: "none", margin:"0 auto"}}>
          确定
      </Button>
            </div>
        )
    }
}

export default StudentList