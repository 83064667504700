import React from 'react';
import { Tabs, WhiteSpace, Badge ,Button,Toast } from 'antd-mobile';
import {axiosGet} from './server/serverapp';


class Bind extends React.Component {
    constructor(props){
        super(props)
        console.log(this.props)
        this.state = {phone1:'',phone2:'', name:'', password:''}
        this.phone1Ref = React.createRef()
        this.phone2Ref = React.createRef()
        this.nameRef = React.createRef()
        this.passwordRef = React.createRef()
        this.typeIndex = 0
    }
    componentDidMount(){
        document.title = '账号绑定'
    }
    toBack = ()=>{
        this.props.history.goBack()
    }
    toPromise = async () => {
        let typeIndex = this.typeIndex
        //this.props.history.push('/student')
        let {phone1,phone2, name, password} = {phone1:this.phone1Ref.current.value,phone2:this.phone2Ref.current.value, name:this.nameRef.current.value, password:this.passwordRef.current.value}
        //console.log({phone1,phone2, name, password},typeIndex)
        let realPhone = typeIndex == 0?phone1:phone2
        if(realPhone.substr(0,1) !== "1" || realPhone.length !== 11){
            Toast.info("请输入正确的手机号！", 3);
            return
        }
        if(typeIndex == 0 && name == ''){
            Toast.info("请输入孩子姓名！", 3);
            return
        }
        if(typeIndex == 1 && password == ''){
            Toast.info("请输入孩子密码！", 3);
            return
        }
        const islogin = await axiosGet('histdata',{phone:realPhone, name, password});
        if(islogin.retCode == '0'){
            this.props.history.push('/student',{
                dotData: islogin.retData.sutdent
            })
        }else if(islogin.retCode == '1000'){
            Toast.info(islogin.retMsg, 3);
            setTimeout(()=>{this.props.history.push('/mimaerror',{
                phoneNum : realPhone
            })},1000)
        }else{
            Toast.info(islogin.retMsg, 3);
        }
        
        
        console.log(222,islogin)
    }
    render() {
        let {phone, name, password} = this.state
        let inputimg = [process.env.PUBLIC_URL + '/static/img/bindback.png',process.env.PUBLIC_URL + '/static/img/pername.png',process.env.PUBLIC_URL + '/static/img/perphone.png']
        const tabs = [
            { title: <Badge>账号姓名</Badge> },
            { title: <Badge>账号密码</Badge> },
          ];
          const TabExample = () => (
            <div>
                <Tabs tabs={tabs}
                
                initialPage={0}
                tabBarActiveTextColor='#30CC75'
                tabBarInactiveTextColor="#8C8F91"
                tabBarUnderlineStyle={{'borderTop':'none','borderBottomColor':'#30CC75','borderBottomWidth':"3px"}}
                onChange={(tab, index) => { this.typeIndex=index }}
                onTabClick={(tab, index) => { this.typeIndex=index }}
              >
                <div style={{ height: '220px', backgroundColor: '#fff' ,overflow:'hidden'}}>
                    <div style={{width:"94%",position:"relative",margin:'28px auto'}}>
                        <input type='text' defaultValue={phone} ref={this.phone1Ref} maxLength='11' onChange={this.phoneChange} style={{backgroundColor:'#F9F9F9',width:"100%",height:"48px",outline:"none",borderRadius:"24px",border:'none',padding:'0 50px'}} placeholder="请输入孩子登录英语说的手机号" />
                        <img style={{ width: '20px',height:"20px",position:'absolute',left:'20px',top:"14px" }} src={inputimg[2]} />
                    </div>
                    <div style={{width:"94%",position:"relative",margin:'24px auto'}}>
                        <input type='text' defaultValue={name} ref={this.nameRef} style={{backgroundColor:'#F9F9F9',width:"100%",height:"48px",outline:"none",borderRadius:"24px",border:'none',padding:'0 50px'}} placeholder="请输入孩子姓名" />
                        <img style={{ width: '20px',height:"20px",position:'absolute',left:'20px',top:"14px" }} src={inputimg[1]} />
                    </div>
                        
                    
                
                </div>
                <div style={{ height: '220px', backgroundColor: '#fff' ,overflow:'hidden'}}>
                    <div style={{width:"94%",position:"relative",margin:'28px auto'}}>
                        <input type='text' defaultValue={phone} ref={this.phone2Ref} maxLength="11" style={{backgroundColor:'#F9F9F9',width:"100%",height:"48px",outline:"none",borderRadius:"24px",border:'none',padding:'0 50px'}} placeholder="请输入孩子登录英语说的手机号" />
                        <img style={{ width: '20px',height:"20px",position:'absolute',left:'20px',top:"14px" }} src={inputimg[2]} />
                    </div>
                    <div style={{width:"94%",position:"relative",margin:'24px auto'}}>
                        <input type='text' defaultValue={password} ref={this.passwordRef} style={{backgroundColor:'#F9F9F9',width:"100%",height:"48px",outline:"none",borderRadius:"24px",border:'none',padding:'0 50px'}} placeholder="请输入孩子密码" />
                        <img style={{ width: '20px',height:"20px",position:'absolute',left:'20px',top:"14px" }} src={inputimg[1]} />
                    </div>
                        
                </div>
              </Tabs>
              <WhiteSpace />
              </div>
          )
        return (
            <div>

                {/* <NavBar
                    mode="light"
                    icon={<Icon type="left" />}
                    onLeftClick={this.toBack}
                >
                    账号绑定
                </NavBar> */}
                
                <div style={{position:'relative'}}>
                    <img style={{ width: '100%' }} src={inputimg[0]} />
                </div>
                <div style={{width:'90%',height:'240px',position:'absolute',top:'140px',left:'5%',boxShadow:"0 2px #efefef",borderRadius:'16px',overflow:'hidden'}}>
                    <TabExample></TabExample>
                </div>
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <Button type="primary" onClick={this.toPromise} style={{ width:"90%",height:'48px',borderRadius:'24px',backgroundColor: "#30CC75", outline: "none", margin:"0 auto"}}>
          绑定
      </Button>
            </div>
        )
    }
}

export default Bind;