import React from 'react'
import { Modal, WhiteSpace, List, Toast, Button } from 'antd-mobile';
import { axiosGet } from './server/serverapp';
const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;
class StudentList extends React.Component {
    constructor(props) {
        super(props)
        this.state = { list: [] }
    }
    toBind = () => {
        this.props.history.push('/bind')
    }
    toNextPromise = () => {
        this.props.history.push('/student')
    }
    async componentDidMount() {
        document.title = '绑定设置'
        const isloginlist = await axiosGet('histOpen');
        this.setState({ list: isloginlist.retData.sutdent_list })
    }
    render() {
        let stuList = this.state.list
        const noList = () => {
            return (
                <div>
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace /><WhiteSpace />
                    <div className="tittle">
                        亲爱的家长，欢迎使用【英语说家长通！】
            </div>
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    <p>
                        绑定孩子账号后
            </p>
                    <p>
                        可免费接收作业通知和学情报告
            </p>
                    <img style={{ width: '100%' }} alt="tu" src={process.env.PUBLIC_URL + '/static/img/appchild.png'} />
                </div>
            )
        }
        const listItems = stuList.map((item) =>
            <List key={item.student_id}>
                <Item multipleLine extra="解除绑定"
                    onClick={() =>
                        alert('解除绑定', `您确定要解除${item.user_name}的绑定吗?`, [
                            { text: '取消', onPress: () => console.log('cancel') },
                            {
                                text: '确定',
                                onPress: async () => {
                                    const islogin = await axiosGet('closeStudent', { user_id: item.student_id });
                                    console.log(islogin)
                                    Toast.info(islogin.retMsg, 3);
                                    if (islogin.retCode === 0) {
                                        const loadData = await axiosGet('histOpen');
                                        if(loadData.retData.sutdent_list.length === 0){
                                            this.props.history.push('/bind')
                                            return
                                        }
                                        this.setState({ list: loadData.retData.sutdent_list })
                                    }
                                }
                            },
                        ])
                    }


                    thumb={item.user_avatar}>
                    {item.user_name}
                    <Brief>
                        {item.user_phone}
                    </Brief>
                </Item>
            </List>
        );

        return (
            <div>
                <div>
                    {stuList.length === 0 ? noList : listItems}
                </div>
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace /><WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <Button type="primary" onClick={this.toBind} style={{ width: "90%", height: '48px', borderRadius: '24px', backgroundColor: "#30CC75", outline: "none", margin: "0 auto" }}>
                    添加孩子
      </Button>
            </div>
        )
    }
}

export default StudentList