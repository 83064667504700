import Axios from 'axios'
import { stringify } from 'qs';
import Url from "url"

/* Axios.interceptors.request.use(config => {
    config.headers['Content-Type'] =
           'application/x-www-form-urlencoded';
           config.data = stringify(config.data);
     return config
 }, error => {
     return Promise.reject(error)
 }) */
Axios.interceptors.response.use(
        response => {
        console.log(response)
            //const res = response.data;
            return response.data;
            //这里根据后台返回来设置
            /* if (res.msg === "success") {
                return response.data;
            } else {
                return Promise.reject(error);
            } */
        },
        error => {
            return Promise.reject(error)
        }
    )
    
//const HISTORY= 'https://api-test.stkouyu.cn/';
const HISTORY= 'https://api.stkouyu.cn/';
const URL ={
    histOpen:HISTORY+'v3/user/account-binding',//list
    histdata:HISTORY+'v3/user/account-binding/confirm', //
    getCode: HISTORY+'v3/user/account-binding/send-code',
    saveStudent:HISTORY+'v3/user/account-binding/save',
    closeStudent:HISTORY+'v3/user/account-binding/edit'//解绑
}

    let httpUrl = "?"+window.location.href.split("?")[1]
    let openid = Url.parse(httpUrl, true).query.openid || "";
    let access_token = Url.parse(httpUrl, true).query.access_token || "";
    console.log(window.location.href,openid,access_token)
//Axios.defaults.withCredentials = true;
export function axiosGet(loade,data){
    let dataandc = {...data,openid,access_token}
    return Axios.get(URL[loade],{
        params: dataandc
      })
}
//接口1方法
/* export function axiosGet(loade,data){
    return server({
        url: URL[loade],
        method: 'get',
        dataType: "json",
    contentType: "application/x-www-form-urlencoded;charset=UTF-8",
        data: data
    })
} */

//config.headers['Content-Type'] = 'application/x-www-form-urlencoded';