import React from 'react'
import { WhiteSpace ,Button,Toast,Text } from 'antd-mobile';
import {axiosGet} from './server/serverapp';
class Topay extends React.Component{
   /* {
	"user_id": 44534,
	"user_phone": 16655879967,
	"user_name": "鲁初雪",
	"user_avatar": "https://sojson.nimatool.com/ui/images/logo_online.png",
	"class_name": "XXX体验版",
	"school_name": "XXX体验学校",
	"pay_info": {
		"title": "帮我升级「英语说」学习卡",
		"desc": "英语说致力于用领先的技术提升您孩子的英语听说能力。",
		"link": "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0ce71e5fb34355bd&redirect_uri=https%3A%2F%2Fh5-test.stkouyu.cn%2Fhomework-report%2Fh5-pay.html%3Fuser_id%3D82827%26api_type%3D1&response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect",
		"imgUrl": "https://jzt-stkouyu-cn.oss-cn-hangzhou.aliyuncs.com/logo.png"
	}
} */
    toPay = async () => {
        window.location.href = this.props.location.state.payData.pay_info.link;
    }
    componentDidMount(){
        document.title = '确认绑定'
    }
    render(){
        let inputimg = [
            process.env.PUBLIC_URL + '/static/img/bindback.png',
            process.env.PUBLIC_URL + '/static/img/see.png',
            process.env.PUBLIC_URL + '/static/img/topay.png',
        ]
        let {user_avatar, user_name, user_phone, school_name, class_name } = this.props.location.state.payData
        return (
            <div>
                <div style={{position:'relative'}}>
                    <img style={{ width: '120px', height:'114px', display:'block', margin: '30px auto 10px' }} src={inputimg[2]} />
                </div>
                <p style={{fontSize:'20px', fontWeight: 'bold', textAlign: 'center'}}>绑定成功</p>
                <p style={{fontSize:'12px', fontWeight: '400', textAlign: 'center'}}>激活正式学习卡，解锁更多听说训练内容</p>
                <div style={{width:'90%',height:'300px',borderRadius:'16px',overflow:'hidden', margin: '10px auto'}}>
                    
                    <div style={{width:"94%",height:"200px",backgroundImage: 'url('+inputimg[1]+')',backgroundSize:"100% 100%",margin:"40px auto 0",position:'relative',paddingTop:"1px"}}>
                        <div style={{width:"52px",height:"52px",position:"absolute",top:"-26px",left:"50%",marginLeft:"-26px",backgroundColor:"#F9F9F9",borderRadius:"50%",overflow:"hidden"}}>
                            <img style={{width:'100%'}} src={user_avatar} />
                        </div>
                        <p style={{fontSize:"16px",marginTop:"33px",textAlign:"center"}}>
                            {user_name}
                        </p>
                        <div style={{textAlign:"left",textIndent:"28%"}}>
                            <p style={{fontSize:"16px",marginTop:"30px"}}>
                                <span style={{color:"#30CC75"}}>账号：</span>
                                <span>{user_phone}</span>
                            </p>
                            <p style={{fontSize:"16px"}}>
                                <span style={{color:"#30CC75"}}>学校：</span>
                                <span>{school_name}</span>
                            </p>
                            <p style={{fontSize:"16px"}}>
                                <span style={{color:"#30CC75"}}>班级：</span>
                                <span>{class_name}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <WhiteSpace />
                <WhiteSpace />
                {
                    class_name == '暂未加入班级' ? null :
                    <Button type="primary" onClick={this.toPay} style={{ width:"90%",height:'48px',borderRadius:'24px',backgroundColor: "#30CC75", outline: "none", margin:"0 auto"}}>
                        立即支付
                    </Button>
                }
                
            </div>
        )
    }
}



export default Topay